import React, { useEffect, useState } from "react";
import { Outlet, Navigate } from "react-router-dom";
import authService from "./services/authService";

const ProtectedRoutes = () => {
  const [currentUser, setCurrentUser] = useState(undefined);

  useEffect(() => {
    const user = authService.getCurrentUser();

    setCurrentUser(!!user);
  }, []);

  if (currentUser === undefined) {
    return null;
  }

  return currentUser ? <Outlet /> : <Navigate to="/auth" replace />;
};

export default ProtectedRoutes;
