import React, { useEffect, useRef } from "react";
import styles from "./styles.module.scss";
import { Transform } from "./components/transform";
import { ResultsSlider } from "./components/results";
import { FAQSection } from "./components/faq";
import { Reviews } from "./components/reviews";
import { Plan } from "./components/plan";
import { Timer } from "./components/timer";
import { Benefits } from "./components/benefits";
import { Partners } from "./components/partners";
import { Guarantee } from "./components/guarantee";
import { DiscountProvider } from "./DiscountContext";
import { PersonalPlan } from "./components/personalPlan";
import { ProfileCard } from "./components/profileCard";
import { PaywallChart } from "./components/chart";

export const Paywall = () => {
  const faqSectionRef = useRef(null);
  const element1Ref = useRef(null);
  const element2Ref = useRef(null);
  const element3Ref = useRef(null);

  const elements = [element1Ref, element2Ref, element3Ref];

  const handleScrollToClosest = () => {
    const scrollTop = window.scrollY;

    const closestElement = elements.reduce((closest, current) => {
      const currentOffsetTop =
        current.current.getBoundingClientRect().top + scrollTop;
      const closestOffsetTop =
        closest.current.getBoundingClientRect().top + scrollTop;

      const currentDistance = Math.abs(currentOffsetTop - scrollTop);
      const closestDistance = Math.abs(closestOffsetTop - scrollTop);

      return currentDistance < closestDistance ? current : closest;
    });

    closestElement.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  return (
    <DiscountProvider>
      <div className={styles.layout}>
        <Timer onGetMyPlanClick={handleScrollToClosest} />
        <Transform />
        <PersonalPlan />
        <div ref={element1Ref}>
          <Plan id="1" title="AI-optimized plan" isMetrics />
        </div>
        <ProfileCard />
        <PaywallChart />
        <div ref={element2Ref}>
          <Plan id="2" title="Get visible results in 4 weeks!" />
        </div>
        <Benefits />
        <Partners />
        <ResultsSlider />
        <div ref={faqSectionRef}>
          <FAQSection />
        </div>
        <Reviews />
        <div ref={element3Ref}>
          <Plan id="3" title="Get visible results in 4 weeks!" />
        </div>
        <Guarantee />
        <footer className={styles.footer}>
          Straiton Limited | Alpha Tower, Floor 1, Office 11, <br /> Pavlou
          Nirvana- Aipeias, Limassol, 3021, Cyprus
        </footer>
      </div>
    </DiscountProvider>
  );
};
