import { useDispatch, useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router";
import { Loader } from "../common/loader";
import { useLayoutEffect } from "react";
import { getUserInfoThunk } from "../redux/slices/userSlice";

export const RoutesWithEmail = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.user);

  useLayoutEffect(() => {
    dispatch(getUserInfoThunk({ checkForEmail: true, navigate }));
  }, []);

  return isLoading ? <Loader /> : <Outlet />;
};
