import styles from "./styles.module.scss";

export const TrainingDays = ({
  onChangeDay,
  allExercieses,
  activeDayIndex,
  userDaysProgress,
}) => {
  const handleChangeDay = (workout, index) => {
    if (userDaysProgress <= index) {
      return;
    }
    onChangeDay(workout, index);
  };
  return (
    <div className={styles.trainingDays}>
      {allExercieses.map((workout, index) => (
        <div
          onClick={() => handleChangeDay(workout, index)}
          key={index}
          className={
            userDaysProgress > index
              ? index + 1 === activeDayIndex
                ? `${styles.dayItem} ${styles.active}`
                : `${styles.dayItem} ${styles.complete}`
              : `${styles.dayItem}`
          }
        >
          {workout.name}
        </div>
      ))}
    </div>
  );
};
