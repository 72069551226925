import styles from "./styles.module.scss";
import email from "../../assets/img/png/email.png";
import { useSelector } from "react-redux";
import { axiosInstance } from "../../api";
import { errorToast, successToast } from "../../utils/toaster";

export const ThankyouPage = () => {
  const { userInfo } = useSelector((state) => state.user);
  const handleResend = async () => {
    try {
      await axiosInstance.post("/me/send-portal-access-email");
      successToast({ message: "Email has been sent" });
    } catch (e) {
      errorToast({ message: "Unexpected error occured" });
      console.log(e);
    }
  };

  return (
    <div className={styles.thankyou}>
      <img src={email} alt="email" />
      <h3>Check your email</h3>
      <h5>
        We’ve sent sent login instructions to your email address:
        <br />
        <span>{userInfo?.email}</span>
      </h5>
      <p className={styles.resend}>
        Didn’t receive an email? <a onClick={handleResend}>Resend</a>
      </p>
    </div>
  );
};
