import React, { useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Steps } from "./data/steps";
// import { updateAnswer } from "../../../redux/slices/answersSlice";
import styles from "./styles.module.scss";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import "./fade.css";
import { UserService } from "../../../api/services/userService";

export const CurrentStep = () => {
  const { stepIndex } = useParams();
  const navigate = useNavigate();
  const nodeRef = useRef();

  const currentStepIndex = parseInt(stepIndex, 10) - 1;
  const currentStep = Steps[currentStepIndex];
  const Component = currentStep.component;

  const handleNextStep = (answer) => {
    const mappedKey = currentStep?.mappingKey;

    if (answer !== undefined && mappedKey) {
      UserService.updateQuestions({ [mappedKey]: answer });
    }

    if (answer === null && mappedKey === "qqOccasion") {
      navigate(`/onboarding/${currentStepIndex + 3}`);
      return;
    }

    if (currentStepIndex + 1 >= Steps.length) {
      navigate("/create-plan");
      return;
    }

    navigate(`/onboarding/${currentStepIndex + 2}`);
  };

  return (
    <SwitchTransition mode="out-in">
      <CSSTransition
        key={currentStepIndex}
        in={true}
        nodeRef={nodeRef}
        timeout={700}
        classNames="fade-in"
      >
        <div ref={nodeRef} className={styles.step}>
          <Component onNextStep={handleNextStep} />
        </div>
      </CSSTransition>
    </SwitchTransition>
  );
};
