import { useNavigate } from "react-router";
import { Button } from "../../../../common";
import { RegistrationLayout } from "../layout";
import styles from "./styles.module.scss";
import { useState } from "react";
import { axiosInstance } from "../../../../api";

export const Name = () => {
  const [name, setName] = useState();
  const [isValid, setIsValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const value = e.currentTarget.value;

    if (value === "") {
      setName("");
      setIsValid(false);
      return;
    }

    setName(value);

    setIsValid(true);
  };

  const onSubmit = async () => {
    try {
      setIsLoading(true);
      await axiosInstance.post("/me/change-name", { newName: name });
      setIsLoading(false);
      navigate("/email");
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <RegistrationLayout hideBack>
      <div className={styles.container}>
        <div className={styles.content}>
          <h2>What's your name?</h2>
          <div className={styles.input}>
            <input
              placeholder="Name"
              type="text"
              value={name}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className={styles.action}>
          <Button onClick={onSubmit} disabled={!isValid || isLoading}>
            Continue
          </Button>
        </div>
      </div>
    </RegistrationLayout>
  );
};
