import { useNavigate, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getWorkouts } from "../../../redux/slices/exerciseSlice";
import keyboardRight from "../../../assets/keyboardRight.svg";
import classes from "./styles.module.scss";
import { Loader } from "../../../common/loader";
import { BottomSheetContainer } from "../shared";
import { Advices } from "../shared/advices";

export const Workout = () => {
  const [open, setOpen] = useState(false);
  const [exercise, setExercise] = useState(null);
  const { title } = useParams();
  const { isLoading, workouts } = useSelector((state) => state.exerciseSlice);
  const workout = workouts.find((item) => item.id === title);

  let exercises = [];

  workout?.sets.forEach((set) => {
    exercises = [...exercises, ...set.exercises];
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleNavigateBack = () => {
    navigate("/workouts");
  };

  useEffect(() => {
    if (!workout) {
      dispatch(getWorkouts());
    }
  }, []);

  return (
    <div className={classes.workout}>
      <div className={classes.header}>
        <div onClick={handleNavigateBack} className={classes.back}>
          <img src={keyboardRight} alt="back" />
        </div>
        <h4>{workout?.name}</h4>
      </div>

      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className={classes.cards}>
            {exercises.map((exercise, index) => (
              <div
                onClick={() => {
                  if (open) {
                    setOpen(false);
                  } else {
                    setOpen(true);
                    setExercise(exercise);
                  }
                }}
                className={classes.card}
                key={index}
              >
                <img src={exercise.previewPicture} alt="" />
                <div className={classes.cardTitle}>
                  <h5>{exercise?.name}</h5>
                  <p>
                    {exercise.durationSec} sec |{" "}
                    <span>{exercise.kcal} kcal</span>
                  </p>
                </div>
              </div>
            ))}
          </div>
          <BottomSheetContainer open={open} setOpen={setOpen}>
            <Advices steps={exercise?.steps} videos={[exercise?.video]} />
          </BottomSheetContainer>
        </>
      )}
    </div>
  );
};
