import styles from "./styles.module.scss";

export const TrainingContent = ({ activeDay, onOpenExercise }) => {
  return (
    <div className={styles.contentWrapper}>
      {activeDay?.exercises?.map((exercise) => {
        return (
          <div
            key={Math.random()}
            className={styles.contentItem}
            onClick={() => onOpenExercise(exercise)}
          >
            <img src={exercise.previewPicture} alt="preview" />
            <div className={styles.infoWrapper}>
              <p className={styles.contentTitle}>{exercise.name}</p>
              <p className={styles.contentSubtitle}>{exercise.subTitle}</p>
            </div>
          </div>
        );
      })}
    </div>
  );
};
