import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import styles from './styles.module.scss';

export const TrainingsHeader = ({progress}) => {
  return (
    <div className={styles.header}>
      <CircularProgressbar
        strokeWidth={16}
        className={styles.headerCircle}
        styles={buildStyles({
          pathColor: "var(--primary)",
        })}
        value={progress}
      />
      Your current progress is {progress} %
    </div>
  );
};
