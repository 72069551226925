import { useParams } from "react-router";
import { Steps } from "../data/steps";
import styles from "./styles.module.scss";

export const ProgressBar = () => {
  const { stepIndex } = useParams();
  const currentStepIndex = parseInt(stepIndex, 10) - 1;

  const currentGroupIndex = Steps[currentStepIndex]?.groupIndex;
  const currentGroupSteps = Steps.filter(
    (step) => step.groupIndex === currentGroupIndex
  );

  const localStepIndex = currentGroupSteps.findIndex(
    (step) => Steps.indexOf(step) === currentStepIndex
  );

  const groupTitles = [
    "My Profile",
    "Activity",
    "Lifestyle & Habits",
    "Nutrition",
    "Almost Done",
  ];

  const stepGroupProgress = () => {
    return ((localStepIndex + 1) / currentGroupSteps.length) * 100;
  };

  return (
    <div className={styles.progressBarContainer}>
      <h3>{groupTitles[currentGroupIndex]}</h3>
      <div className={styles.progressBars}>
        {[...new Set(Steps.map((step) => step.groupIndex))].map((index) => (
          <div key={index} className={styles.progressBarItem}>
            {currentGroupIndex >= index && (
              <div
                style={{
                  width: `${
                    currentGroupIndex === index ? stepGroupProgress() : 100
                  }%`,
                }}
                className={styles.fill}
              ></div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};
