import { Button } from "../../../../common";
import { Loader } from "../../../../common/loader";
import { useGetUserInfo } from "../../../../hooks/useGetUserProfile";
import { RegistrationLayout } from "../layout";
import { ChartGain } from "./ChartGain";
import { ChartLose } from "./ChartLose";
import styles from "./styles.module.scss";
import { useNavigate } from "react-router";

export const PlanChart = () => {
  const { user, loaded } = useGetUserInfo();
  const ms = localStorage.getItem("ms");
  const navigate = useNavigate();

  const weight =
    ms === "us" ? user?.questions?.qqWeightLbs : user?.questions?.qqWeightKg;
  const goal =
    ms === "us"
      ? user?.questions?.qqGoalWeightLbs
      : user?.questions?.qqGoalWeightKg;

  const isLoseWeight = weight > goal && weight !== goal;

  return (
    <RegistrationLayout hideBack>
      {loaded ? (
        <div className={styles.container} id="breakdown">
          <div className={styles.content}>
            <h2>
              <span>{user.name},</span> <br /> your 4-week custom plan is here!
            </h2>

            <div
              className={
                loaded ? styles.chart : `${styles.chart} ${styles.hidden}`
              }
            >
              <div className={styles.title}>Your Weight</div>
              <div className={styles.axisx}>
                <span>Week 1</span>
                <span>Week 2</span>
                <span>Week 3</span>
                <span>Week 4</span>
              </div>
              <div
                className={
                  isLoseWeight
                    ? `${styles.now}`
                    : `${styles.now} ${styles.gain}`
                }
              >
                Now
              </div>
              <div
                className={
                  isLoseWeight
                    ? `${styles.after}`
                    : `${styles.after} ${styles.gain}`
                }
              >
                After <br />4 weeks
              </div>

              {isLoseWeight ? <ChartLose /> : <ChartGain />}
            </div>
            <div className={styles.hint}>
              The chart is for visualization only
            </div>
          </div>

          <div className={styles.action}>
            <Button onClick={() => navigate("/paywall")}>Continue</Button>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </RegistrationLayout>
  );
};
